import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "flexibility",
      "style": {
        "position": "relative"
      }
    }}>{`Flexibility`}</h2>
    <p>{`While we strive to provide you as a developer with a wide range of standalone
components, the final end product will always be a collection of pages. These
pages consist of patterns, which in turn are built out of our components.`}</p>
    <p>{`These patterns and pages are compositions, where there is a certain relationship
between the components. A spatial relationship needs to be established to result
in a user-friendly browsing experience.`}</p>
    <p>{`This calls for a system that is built upon our design system, that allows all
components a certain degree of extensibility. Our implementation is based on
`}<a parentName="p" {...{
        "href": "https://styled-system.com/"
      }}>{`styled-system`}</a>{` which promises it `}<strong parentName="p">{`"lets you
quickly build custom UI components with constraint-based style props based on
scales defined in your theme"`}</strong>{`.`}</p>
    <h2 {...{
      "id": "system-props",
      "style": {
        "position": "relative"
      }
    }}>{`System props`}</h2>
    <p>{`Our implementation allows you to extend a component's styling with a certain set
of properties. These properties are different per component (e.g. we won't allow
you to change the padding on a `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, but you can freely alter padding on a
`}<inlineCode parentName="p">{`Paper`}</inlineCode>{`).`}</p>
    <p>{`Every component will display its System Props under its Propstable, so you can
quickly check what is allowed and what properties are withheld.`}</p>
    <h2 {...{
      "id": "categories",
      "style": {
        "position": "relative"
      }
    }}>{`Categories`}</h2>
    <p>{`All system props also fall in certain categories, and whitelisted properties
from the same category will always be allowed as a whole.`}</p>
    <h3 {...{
      "id": "backgroundprops",
      "style": {
        "position": "relative"
      }
    }}>{`BackgroundProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`bgColor`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "borderprops",
      "style": {
        "position": "relative"
      }
    }}>{`BorderProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`borderBottomColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderBottomStyle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderBottomWidth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderLeftColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderLeftStyle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderLeftWidth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderRightColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderRightStyle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderRightWidth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderStyle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderTopColor`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderTopStyle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderTopWidth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`borderWidth`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "borderradiusprops",
      "style": {
        "position": "relative"
      }
    }}>{`BorderRadiusProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`borderRadius`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "colorprops",
      "style": {
        "position": "relative"
      }
    }}>{`ColorProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`colorProps`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "displayprops",
      "style": {
        "position": "relative"
      }
    }}>{`DisplayProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`display`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "flexprops",
      "style": {
        "position": "relative"
      }
    }}>{`FlexProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`alignSelf`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flex`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flexBasis`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flexGrow`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flexShrink`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`order`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "flexparentprops",
      "style": {
        "position": "relative"
      }
    }}>{`FlexParentProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`alignContent`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`alignItems`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flexDirection`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`flexWrap`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`justifyContent`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "floatprops",
      "style": {
        "position": "relative"
      }
    }}>{`FloatProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`float`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "heightprops",
      "style": {
        "position": "relative"
      }
    }}>{`HeightProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`height`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`maxHeight`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "marginprops",
      "style": {
        "position": "relative"
      }
    }}>{`MarginProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`m`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`mb`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`mr`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`mt`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`mx`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`my`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "paddingprops",
      "style": {
        "position": "relative"
      }
    }}>{`PaddingProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`p`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`pb`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`pl`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`pr`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`pt`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`px`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`py`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "positioningprops",
      "style": {
        "position": "relative"
      }
    }}>{`PositioningProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`position`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bottom`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`left`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`right`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`top`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "textprops",
      "style": {
        "position": "relative"
      }
    }}>{`TextProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fontWeight`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`textAlign`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`fontFamily`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`decoration`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "widthprops",
      "style": {
        "position": "relative"
      }
    }}>{`WidthProps`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`width`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      